<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<template>
    <div class="ratings">
        <svg-icon v-for="num in [0, 1, 2, 3, 4]" :key="num" :name="getIconName(num)" class="ratings__icon" />
        <template v-if="showVotesCount">
            <div class="ratings__value">{{ value.toFixed(1) }}</div>
            <div class="ratings__count">
                <a
                    v-if="numberOfVotes > 0 && showLink"
                    :href="generateLink('vehicle-reviews')"
                    @click.prevent="$emit('link-clicked', 'vehicle-reviews')"
                >
                    ({{ $tc('ratings.count', numberOfVotes, { count: numberOfVotes }) }})
                </a>
                <span v-else> ({{ $tc('ratings.count', numberOfVotes, { count: numberOfVotes }) }})</span>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: 0,
        },
        numberOfVotes: {
            type: Number,
            default: null,
        },
        showLink: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        showVotesCount() {
            return this.numberOfVotes && this.value;
        },
    },
    methods: {
        getIconName(num) {
            if (this.value >= num + 0.95) {
                return 'star';
            }

            if (this.value >= num + 0.75) {
                return 'star_almost_full';
            }

            if (this.value >= num + 0.25) {
                return 'star_half';
            }

            return 'star_border';
        },
        generateLink(sectionName) {
            const fullPath = this.$route.fullPath.replace(/#.*/, ''); // remove hash value

            return this.$config.siteUrl ? `${this.$config.siteUrl}${fullPath}#${sectionName}` : `#${sectionName}`;
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.ratings {
    display: flex;
    align-items: center;
    @include font-size(14px);

    &__icon.svg-icon {
        color: $primary;
        width: 16px;
        height: 16px;
        margin-bottom: 1px;
    }

    &__count {
        margin-left: 4px;
    }

    &__value {
        font-weight: bold;
    }
}
</style>
